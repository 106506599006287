<template>
  <Container :title="$t('companies.myCompanies')">
    <v-data-table
      :headers="headers"
      :items="companies"
      :hide-default-footer="true"
      class="elevation-0"
    />
  </Container>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: "Companies",
  props: {
    type: {
      type: String,
      default: "primary",
    },
    showMore: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      companies: [],
      headers: [
        { text: this.$t('tableHeaders.name'),                align: 'start',   sortable: false,   value: 'name'                 },
        { text: this.$t('tableHeaders.registrationNumber'),  align: 'start',   sortable: false,   value: 'registration_number'  },
        { text: this.$t('tableHeaders.email'),               align: 'start',   sortable: false,   value: 'email'                },
        { text: this.$t('tableHeaders.phone'),               align: 'start',   sortable: false,   value: 'phone'                },
        { text: this.$t('tableHeaders.country'),             align: 'start',   sortable: false,   value: 'country'              },
        { text: this.$t('tableHeaders.address'),             align: 'start',   sortable: false,   value: 'address'              },
      ],
    }
  },
  mounted() {
    this.$http.get(`/companies/`).then((company) => {
      this.companies = company.data.results
    })
  },
})
</script>
